import { Config } from '../../interfaces/config.interface';
import Logo from './logo.svg';
import LogoExtra from './globanteers.png';

const config: Config = {
  logo: Logo,
  logo_width: '180px',
  auth: {
    logo_extra: LogoExtra,
  },
  name: 'Globant',
  theme: {
    primary: '#BFD732',
    secondary: '#444444',
  },
  has_hours: false,
  has_reminder: false,
  has_tour: false,
  profile_globant: true,
  activities_certificate_table: true,
  activities: {
    card: {
      weAreIn: false,
    },
    postulate: {
      comment: false,
    },
  },
  map_hide: true,
  idiom: 'es',
};

export default config;
